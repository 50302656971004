import {
  BaseEdge,
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  useViewport,
} from '@xyflow/react';
import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { useFlowchart } from '../../../../../contexts/FlowchartControlProvider';
import initTranslations from '../../../../../lib/initTranslations';
import InputField from '../../../../design_system/Triage/InputField';
import { deprecated } from '../../../../styled/TypeSystem';
import { getEdgeCoords } from '../../lib/getEdgeCoords';

const t = initTranslations('curriculums_view.flowchart');

const StyledInputField = styled(InputField)`
  box-shadow: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderDefault}`};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme }) => theme.vars.textDefault};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  min-width: 3.68rem;
  width: auto;
  ${deprecated.fontSm3};

  &:focus {
    box-shadow: initial;
    border-color: ${({ theme }) => theme.vars.borderDefault};
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  }

  ::placeholder {
    text-align: center;
  }
`;

const HiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  ${deprecated.fontSm3};
`;

const EdgeText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.vars.textDefault};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  max-width: 12rem;
  text-wrap: wrap;
`;

type EdgeInputWrapperProps = {
  $labelX: number;
  $labelY: number;
};

const EdgeInputWrapper = styled.div.attrs<EdgeInputWrapperProps>(({ $labelX, $labelY }) => ({
  style: {
    transform: `translate(-50%, -50%) translate(${$labelX}px, ${$labelY}px)`,
  },
}))<EdgeInputWrapperProps>`
  display: flex;
  position: absolute;
  pointer-events: all;
  max-width: 12rem;
  ${deprecated.fontSm3};
`;

type EditableLabelEdge = Edge<{ label: string }>;

const EditableLabelEdge = ({ id, selected, markerEnd, ...props }: EdgeProps<EditableLabelEdge>) => {
  const {
    vars: { accentPrimaryDefault, textDefault, foundationSurface1 },
  } = useTheme();
  const { readonly, flowchartHandlers } = useFlowchart();
  const { setEdges, showEdgeLabelInputField, editingEdgeId, setShowEdgeLabelInputField } =
    flowchartHandlers;

  const { sourceX, sourceY, targetX, targetY } = getEdgeCoords(props);
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    ...props,
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const [text, setText] = useState(props.data?.label || '');
  const [inputWidth, setInputWidth] = useState(74);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);
  const theme = useTheme();
  const { zoom } = useViewport();
  const isEditing = selected && !readonly && showEdgeLabelInputField && editingEdgeId === id;

  const edgesStyle = {
    stroke: textDefault,
    strokeWidth: 2,
  };

  const handleInputChange = useCallback(
    (e) => {
      const newText = e.target.value;
      setText(newText);
      setEdges((currentEdges) =>
        currentEdges.map((edge) => {
          if (edge.id === id) {
            return { ...edge, data: { ...edge.data, label: newText } };
          }
          return edge;
        })
      );
    },
    [id, setEdges]
  );

  const calculateCircleSize = (baseRadius: number) => {
    return baseRadius / zoom;
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setShowEdgeLabelInputField(false);
    }
  };

  useEffect(() => {
    if (hiddenSpanRef.current) {
      setInputWidth(hiddenSpanRef.current.offsetWidth + 20);
    }
  }, [text]);

  const endPositions = [
    [sourceX, sourceY],
    [targetX, targetY],
  ];

  return (
    <>
      <BaseEdge id={id} markerEnd={markerEnd} path={edgePath} style={edgesStyle} />
      {selected && (
        <>
          {endPositions.map(([x, y], index) => (
            <circle
              className='nodrag nopan'
              cx={x}
              cy={y}
              fill={foundationSurface1}
              key={index}
              r={calculateCircleSize(4)}
              stroke={accentPrimaryDefault}
              strokeWidth={1.5 / zoom}
            />
          ))}
        </>
      )}
      <EdgeLabelRenderer>
        <EdgeInputWrapper $labelX={labelX} $labelY={labelY}>
          {isEditing && (
            <>
              <HiddenSpan data-testid='editable-text' ref={hiddenSpanRef}>
                {text || t('placeholder')}
              </HiddenSpan>
              <StyledInputField
                autoFocus
                className='nodrag nopan'
                data-testid='edge-input-field'
                maxLength={150}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder={t('placeholder')}
                style={{ width: inputWidth, backgroundColor: theme.vars.foundationBase1 }}
                type='text'
                value={text}
              />
            </>
          )}
          {!isEditing && text && <EdgeText data-testid={`edge-${id}-text`}>{text}</EdgeText>}
        </EdgeInputWrapper>
      </EdgeLabelRenderer>
    </>
  );
};

export default EditableLabelEdge;
