import React from 'react';
import styled from 'styled-components';

import ContentSvg from '../../../../../../images/flowchart/content.svg';
import GroupsSvg from '../../../../../../images/flowchart/group.svg';
import PersonSvg from '../../../../../../images/flowchart/person.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import { NodeConnection } from '../../../../../../types/Flowchart';
import { deprecated } from '../../../../../styled/TypeSystem';
import { sharedToolbarButtonStyles } from '../../../../editor/toolbar/buttons/components/ToolbarButton';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  height: ${({ theme: { constants } }) => constants.heightSm};
  padding-inline: ${({ theme: { constants } }) => constants.spacerSm3};
  border: none;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  outline: none;
  ${sharedToolbarButtonStyles};
`;

const ButtonImages = styled.div`
  display: flex;
  height: 1.25rem;
`;

const ButtonText = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${deprecated.fontSm3};
`;

const t = initTranslations('curriculums_view.flowchart');

type ConnectionButtonProps = {
  active: boolean;
  connections: NodeConnection[];
};

const ConnectionsButton = ({ active, connections }: ConnectionButtonProps) => {
  const count = connections.length;
  const connectionsText = t('shape_toolbar.connections.button', { count });

  return (
    <StyledButton active={active}>
      <ButtonImages>
        <img src={ContentSvg} />
        <img src={PersonSvg} />
        <img src={GroupsSvg} />
      </ButtonImages>
      <ButtonText>{connectionsText}</ButtonText>
    </StyledButton>
  );
};

export default ConnectionsButton;
